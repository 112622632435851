import { configureStore } from "@reduxjs/toolkit";
import appReducer from "./../features/app/appSlice";
import languageReducer from "./../features/language/languageSlice";

export const store = configureStore({
  reducer: { language: languageReducer, app: appReducer },
});

// export default store;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
