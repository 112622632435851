export interface AppState {
  value: {
    loading: boolean;
  };
}

export const initialState: AppState = {
  value: {
    loading: true,
  },
};
