import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { initialState } from "./languageState";

export const languageSlice = createSlice({
  name: "language",
  initialState,
  reducers: {
    changeLanguage: (state, action: PayloadAction<string>) => {
      localStorage.setItem("yeswa_language", action.payload);
      state.value.current = action.payload;
    },
  },
});

export const { changeLanguage } = languageSlice.actions;
export const getLanguage = (state: RootState) => {
  return {
    current: state.language.value.current,
    data: state.language.value[
      state.language.value.current as keyof typeof state.language.value
    ],
  } as { current: string; data: Array<string | any> | any };
};
export default languageSlice.reducer;
