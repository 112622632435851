import { MDBContainer, MDBSpinner } from "mdb-react-ui-kit";
import mainBg from "../assets/img/header-bg.webp";
import YeswaLogo from "../assets/img/logo.svg";

const Loader = () => (
  <MDBContainer
    id="track"
    fluid
    className="track vh-100"
    style={{ backgroundImage: `url(${mainBg})` }}
  >
    {/* <div className="bg-image vh-100 text-center"> */}
    <div className="mask">
      <div className="d-flex flex-column justify-content-center align-items-center h-100">
        <MDBSpinner
          grow
          className="me-3 mb-4 text-center"
          style={{
            width: "fit-content",
            height: "auto",
            padding: "2rem",
            animation: "1s linear infinite spinner-grow !important",
          }}
        >
          <img src={YeswaLogo} alt="Yeswa Logistics" height="50" />
        </MDBSpinner>
        <MDBSpinner
          color="warning"
          className="me-3 mx-3 text-center"
          style={{ width: "5rem", height: "5rem" }}
        ></MDBSpinner>
      </div>
    </div>
    {/* </div> */}
  </MDBContainer>
);

export default Loader;
