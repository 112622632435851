import { MDBCol, MDBRow } from "mdb-react-ui-kit";

import c1 from "../assets/img/portfolio/1.jpg";
import c2 from "../assets/img/portfolio/2.jpg";
import c3 from "../assets/img/portfolio/3.jpg";
import c4 from "../assets/img/portfolio/4.jpg";
import c5 from "../assets/img/portfolio/5.jpg";
import c6 from "../assets/img/portfolio/6.jpg";
import c7 from "../assets/img/portfolio/7.jpg";
import c8 from "../assets/img/portfolio/8.jpg";
import c9 from "../assets/img/portfolio/9.jpg";
import c10 from "../assets/img/portfolio/10.jpg";
import c11 from "../assets/img/portfolio/1.jpg";
import c12 from "../assets/img/portfolio/1.jpg";
import c13 from "../assets/img/portfolio/1.jpg";
import c14 from "../assets/img/portfolio/1.jpg";

import SectionDark from "./SectionDark";
import { useTranslation } from "react-i18next";

export default function IntegrationSection() {
  const { t } = useTranslation();
  const integrations = [
    c1,
    c2,
    c3,
    c4,
    c5,
    c6,
    c7,
    c8,
    c9,
    c10,
    c11,
    c12,
    c13,
    c14,
  ];
  return (
    <SectionDark
      id="our_clients"
      title={t("our_clients")}
      subtitle={t("our_clients_subtitle")}
      className="mb-2 py-3"
    >
      <MDBRow center>
        <MDBCol
          className="text-center marquee"
          size="12"
          sm="8"
          style={{ height: "150px" }}
        >
          <div className="marquee-inner">
            {integrations.map((image, index) => (
              <div className="marquee-item" key={index}>
                <img
                  className="img-fluid"
                  src={image}
                  alt=""
                  style={{ maxWidth: "80%" }}
                />
              </div>
            ))}
          </div>
        </MDBCol>
      </MDBRow>
    </SectionDark>
  );
}
