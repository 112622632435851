import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../app/store";
import { changeLanguage } from "../language/languageSlice";
import { initialState } from "./appState";

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setAppLoading: (state, action: PayloadAction<boolean>) => {
      state.value.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(appChangeLanguage.pending, (state, action) => {
      state.value.loading = true;
    });
  },
});

export const { setAppLoading } = appSlice.actions;
export const getAppLoading = (state: RootState) => state.app.value.loading;
export default appSlice.reducer;

// this thunk will be used in dispatch
export const appChangeLanguage = createAsyncThunk(
  "app/changeLanguage", // just for history logging name
  async (language: string, thunk) => {
    await thunk.dispatch(changeLanguage(language));
  }
);
