import { MDBContainer } from "mdb-react-ui-kit";
import React from "react";

export default function SectionDark({
  title,
  subtitle,
  id,
  bg,
  className,
  children,
}: {
  title: string;
  subtitle?: string;
  id: string;
  bg?: boolean;
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <MDBContainer
      id={id}
      className={`section-dark ${bg && "bg-map"} ${className}`}
      fluid
    >
      <div className="text-center">
        <h1 className="section-heading text-uppercase">{title}</h1>
        <h4 className="section-subtitle text-muted">{subtitle}</h4>
      </div>
      {children}
    </MDBContainer>
  );
}
