import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { store } from "./app/store";
// import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "./App.css";
import "@fortawesome/fontawesome-free/css/all.min.css";

import App from "./App";

import "./i18n";
import ThemeSelector from "./themes/ThemeSelector";
import { HelmetProvider } from "react-helmet-async";
// import "mdb-react-ui-kit/dist/css/mdb.min.css";
// import ThemeSelector from "./themes/ThemeSelector";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <HelmetProvider>
    <React.StrictMode>
      <Provider store={store}>
        <ThemeSelector>
          <App />
        </ThemeSelector>
      </Provider>
    </React.StrictMode>
  </HelmetProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
