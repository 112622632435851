import React, { useCallback, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import Loader from "../components/Loader";
import { getAppLoading, setAppLoading } from "../features/app/appSlice";
// import "mdb-react-ui-kit/dist/css/mdb.min.css";
const Helmet = React.lazy(() => import("./HelmetLoader"));

function ThemeSelector({ children }: { children: React.ReactNode }) {
  const loading = useAppSelector(getAppLoading);
  const dispatch = useAppDispatch();
  const intiTheme = useCallback(() => {
    setTimeout(() => {
      dispatch(setAppLoading(false));
      const sectionId = window.location.href.split("/")[3].split("#")[1];
      document.getElementById(sectionId)?.scrollIntoView();
    }, 1500);
  }, [dispatch]);

  useEffect(() => {
    intiTheme();
    return () => {};
  }, [loading, intiTheme]);

  return (
    <>
      {loading && <Loader />}
      <React.Suspense fallback={null}>
        <Helmet />
      </React.Suspense>
      {!loading && children}
    </>
  );
}

export default ThemeSelector;
