export interface LanguageState {
  value: {
    current: string;
  };
}

export const initialState: LanguageState = {
  value: {
    current: localStorage.getItem("yeswa_language") || "ar",
  },
};
